import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'Comment se passe la prise de la photographie?',
    content: () => (
      <>
        L'écran est tactile, une fois le style de peinture choisi, vos visiteurs n'ont plus qu'à appuyer sur un bouton pour déclencher un compte à rebourd et prendre leur meilleure pose !
      </>
    ),
  },
  {
    title: 'Combien de styles sont disponibles?',
    content: () => (
      <>
        S'ils sont choisis à l'avance, il est possible de proposer une infinité de styles.
      </>
    ),
  },
  {
    title: 'Quelles sont les imprimantes proposées?',
    content: () => (
      <>
        Toutes les imprimantes disponibles à l'achat peuvent être installées.
      </>
    ),
  },
  {
    title: 'Aurai-je besoin d\'installer un nouvel ordinateur?',
    content: () => (
      <>
        Oui, un ordinateur connecté au réseau wifi privé de votre établissement doit être allumé pour effectuer les transformations en temps réel.
      </>
    ),
  },
  {
    title: 'Comment ça marche?',
    content: () => (
      <>
        La transformation d'images en temps réel est rendu possible grâce à un algorithme de machine learning (certains diraient: "intelligence artificielle!"), mais si on regardait plus en détails ce qu'il y a sous le capot,
        on se rendrait vite compte que c'est un réseau de neurones.
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Questions Courantes</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
