import React, {useState} from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ImageCompare from "image-compare-viewer";

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const restartGif = (imgElement) => {
  let element = document.getElementById(imgElement);
  if (element) {
     var imgSrc = element.src;
     element.src = imgSrc;
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "natural.gif",
      styledimages: [
        "kandinskyed.gif",
        "pollocked.gif",
        "mosaiced.gif",
        "moneted.gif",
        "picassoed.gif",
        "starryed.gif",
      ],
      stylized_index: 0
    };
    this.changeStyle = this.changeStyle.bind(this);
  }

  render() {
    return (
    <StaticQuery
      query={graphql`
        query {
          art_build: file(
            sourceInstanceName: { eq: "art" }
            name: { eq: "build" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <HeaderWrapper>
          <Container>
            <Grid>
              <div style={{textAlign: 'center',}}>
                <div id="image-compare">
                  <img id="naturalimage" src={this.state.image} alt=""/>
                  <img id="styledimage" src={this.state.styledimages[this.state.stylized_index]} alt=""/>
                </div>
                <button
                onClick={() => this.changeStyle()}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  paddingTop: '0.1rem',
                  paddingBottom: '0.1rem',
                  marginTop: '1rem',
                  marginBottom: '0.4rem'
                }}
                ><h4>Cliquer pour changer de style</h4>
                </button>
              </div>
              <Text>
                <h1>
                  Un miroir qui transforme vos visiteurs en œuvre d'art
                  <br/>
                  selon le style des peintres exposés
                </h1>
              </Text>
            </Grid>
          </Container>
        </HeaderWrapper>
      )}
    />
  )}

  componentDidMount() {
    const options = {
      smoothing: false,
      hoverStart: true
    }
    const element = document.getElementById("image-compare");
    const viewer = new ImageCompare(element, options).mount();
  }

  changeStyle() {
    let s_image = new Image();
    let i = (this.state.stylized_index + 1) % this.state.styledimages.length;
    s_image.onload = () => {
      restartGif('naturalimage');
      this.setState({
        image: 'natural.gif',
        stylized_index: i
      });
    }
    s_image.src = this.state.styledimages[i];
  }
};

const HeaderWrapper = styled.header`
  padding-top: 120px;
  padding-bottom: 10px;
  background: url('/bg.svg');
  background-size: cover;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 128px;
  }
`;

const Art = styled.figure`
  width: 100%;
  margin: 0;
  padding-bottom: 15px;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: center;
  color: props.theme.color.black.light;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.color.black.regular};
  }
`;

export default Header;
