import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Video from '../common/Video';
import Mycarousel from './Mycarousel';

import { Section, Container } from '@components/global';

class About extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            showcase: file(
              sourceInstanceName: { eq: "art" }
              name: { eq: "showcase" }
            ) {
              childImageSharp {
                fluid(maxWidth: 760) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }

            frame_craft: file(
              sourceInstanceName: { eq: "art" }
              name: { eq: "frame_craft" }
            ) {
              childImageSharp {
                fluid(maxWidth: 760) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }

            printer: file(
              sourceInstanceName: { eq: "art" }
              name: { eq: "printer" }
            ) {
              childImageSharp {
                fluid(maxWidth: 760) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        `}
        render={data => (
          <Section id="présentation">
            <Container>
              <Grid>
                <div>
                  <h2>Apporter une nouvelle expérience</h2>
                  <p>
                    Terminer la visite sur une expérience ludique. Transformer vos visiteurs en peinture en temps réel.
                  </p>
                </div>
                <Art>
                  <Img fluid={data.showcase.childImageSharp.fluid} />
                </Art>
              </Grid>
              <Grid inverse>
              <Art>
                <Video
                  videoSrcURL="https://www.youtube.com/embed/kYKsOfYDUgA"
                  videoTitle="Miroir de Phresque"
                  height="400px"
                  width="100%"
                />
                </Art>
                <div>
                  <h2>Une intégration sur mesure à vos espaces</h2>
                  <p>
                    Nous discutons avec vous de l'intégration de l'écran et de son design pour qu'il s'inscrive au mieux dans votre univers. Que ce soit dans la boutique ou dans un espace dédié.
                  </p>
                </div>
              </Grid>
              <Grid>
                <div>
                  <h2>Un souvenir créé en quelques secondes</h2>
                  <p>
                    Nous proposons directement des impressions tout format sur place, un envoi par email ou bien encore une livraison des affiches choisies.
                    <br />
                    <br />
                  </p>
                </div>
                <Art>
                  <img id="printgif" src={'print.gif'} width='100%' alt=""/>
                </Art>
              </Grid>
              <Grid inverse>
                <Art><Mycarousel /></Art>
                <div>
                  <h2>Et pour les portraits ?</h2>
                  <p>
                    C'est possible aussi: une transformation fidèle à une image donnée ou à un genre artistique.
                    <br />
                    <br />
                  </p>
                </div>
              </Grid>
            </Container>
          </Section>
        )}
      />
    )
  }
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
