import React from "react"
import { StaticQuery, graphql } from 'gatsby';

import "react-responsive-carousel/lib/styles/carousel.min.css";
var Carousel = require('react-responsive-carousel').Carousel;

const images = [
  'zooey.png',
  'buzz.png',
  'child.png',
  'face3.png',
  'face6.png'
]

const Mycarousel = () => (
  <Carousel
    showArrows={true}
    showThumbs={false}
    showStatus={false}
    showIndicators={false}
    infiniteLoop={true}
    >
    {images.map(i => (
      <div>
        <img src={i} />
      </div>
    ))}
  </Carousel>
);

export default Mycarousel
